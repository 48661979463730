import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

const loaderData = require("../img/loader.svg");

export type ILoaderProps = {className?: string} & ISizeScenarios & ICustomWrapScenarios & IScaleScenario & IFullWidthScenario;

// In case custom size is provided, don't provide regular size string union
type ISizeScenarios = IStandardSizeScenario | ICustomSizeScenario;

type IStandardSizeScenario = {
    size?: "sm" | "md" | "lg";
    customSize?: never;
};

type IFullWidthScenario = {
    size?: "sm" | "md" | "lg";
    fullWidth?: boolean;
};

type ICustomSizeScenario = {
    customSize?: string;
    size?: never;
};

// In case custom padding is provided, disable noWrap prop
type ICustomWrapScenarios = ICustomPaddingScenario | INoWrapScenario;

type IScaleScenario = {
    scaleToContainer?: boolean;
};

type ICustomPaddingScenario = {
    customPadding?: string;
    noWrap?: never;
};

type INoWrapScenario = {
    noWrap?: boolean;
    customPadding?: never;
};

//  Design system
const loaderSize = {
    sm: "2.4rem",
    md: "3.2rem",
    lg: "4rem"
};

const paddingSize = {
    sm: "0.8rem 0",
    md: "1.2rem 0",
    lg: "1.6rem 0"
};

//  Component
export const Loader = (props: ILoaderProps) => {
    const {size = "sm", customPadding, customSize, scaleToContainer, fullWidth} = props;

    const iconSize = customSize ? customSize : loaderSize[size];

    return (
        <LoaderWrap
            className={props.className}
            fullWidth={fullWidth}
            scaleToContainer={scaleToContainer}
            padding={customPadding ? customPadding : paddingSize[size]}
        >
            <LoaderIconWrap iconSize={iconSize} scaleToContainer={scaleToContainer}>
                <LoaderIcon />
            </LoaderIconWrap>
        </LoaderWrap>
    );
};

//  Styles and components
const LoaderIconWrap = styled.div<{iconSize: string; scaleToContainer?: boolean}>`
    display: inline-flex;

    ${({scaleToContainer, iconSize}) =>
        scaleToContainer
            ? css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  height: 100%;
                  width: auto;
              `
            : css`
                  object {
                      width: ${iconSize};
                      height: ${iconSize};
                  }
              `}
`;

const LoaderIcon = () => <object type="image/svg+xml" data={loaderData} />;

//  Styles
const LoaderWrap = styled.div<{padding: string; scaleToContainer?: boolean; fullWidth?: boolean}>`
    display: inline-flex;
    border-radius: 18rem;
    justify-content: center;

    ${({padding}) => css`
        padding: ${padding};
    `}

    ${({scaleToContainer}) =>
        scaleToContainer &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 0;
        `}
    
        ${({fullWidth}) =>
        fullWidth &&
        css`
            width: 100%;
        `}
`;
